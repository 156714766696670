import { IIconComponentProps } from '../../types/icons';
import { twMerge } from '../../index';

export default function FuelCardIcon(props: IIconComponentProps) {
  return (
    <svg
      className={twMerge(props.className)}
      height='43.438'
      viewBox='0 0 65.964 43.438'
      width='65.964'
      xmlns='http://www.w3.org/2000/svg'>
      <g data-name='Group 2080' id='Group_2080' transform='translate(0 -0.001)'>
        <path
          d='M48.88,32.6h0a3.687,3.687,0,0,1-.98-2.478,4.123,4.123,0,1,1,4.114,3.842H52A4.182,4.182,0,0,1,48.88,32.6m-10.643-2.48a3.984,3.984,0,0,1,4.108-3.843h0a4.179,4.179,0,0,1,3.134,1.363,6.354,6.354,0,0,0-.5,2.48,6.286,6.286,0,0,0,.5,2.459,4.052,4.052,0,0,1-3.078,1.381H42.35a4,4,0,0,1-4.112-3.84M52,23.554a7.3,7.3,0,0,0-4.814,1.789A7.2,7.2,0,0,0,42.4,23.554h-.05a6.809,6.809,0,0,0-7.029,6.566,6.81,6.81,0,0,0,7.029,6.566,7.126,7.126,0,0,0,4.831-1.81,7.178,7.178,0,0,0,4.832,1.81,6.808,6.808,0,0,0,7.019-6.574,6.81,6.81,0,0,0-7.026-6.557H52M2.958,18.64H63V40.695H2.958Zm0-6.484H63V15.9H2.958Zm0-9.411H63V9.415H2.958ZM2.525,0A2.446,2.446,0,0,0,0,2.339V42.057a1.373,1.373,0,0,0,.436.976,1.52,1.52,0,0,0,1.045.407H63.439A2.446,2.446,0,0,0,65.964,41.1V2.339A2.446,2.446,0,0,0,63.442,0Z'
          data-name='Path 4831'
          fill='currentColor'
          id='Path_4831'
          transform='translate(0 -0.001)'
        />
      </g>
    </svg>
  );
}
