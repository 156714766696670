import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useStore } from '../../../store/store';
import InstitutionAdminDashboard from '../../../containers/InstitutionAdminDashboard/InstitutionAdminDashboard';
import { twMerge } from '../../../index';
import SubpageHeader from '../../SubpageHeader/SubpageHeader';
import PiggyBankIcon from '../../Icons/PiggyBankIcon';
import MyTextMaskedInput from '../../FormControls/MyTextMaskedInput/MyTextMaskedInput';
import { Form, Formik } from 'formik';
import MaskHelpers from '../../../helpers/MaskHelpers';
import { Button, Spinner } from 'react-bootstrap';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';
import RegexHelpers from '../../../helpers/RegexHelpers';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { PdfViewer } from '../../PdfViewer/PdfViewer';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@4.8.69/build/pdf.worker.min.mjs`;
interface IInstitutionAdminFundRegulationsFormProps {
  onSave?: () => void;
}
export function InstitutionAdminFundRegulationsForm(props: IInstitutionAdminFundRegulationsFormProps) {
  const { userStore, modalStore } = useStore();
  const [accepted, setAccepted] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(true);
  const handleAccept = () => {
    setSent(false);
    if (userStore.currentUserInstId) {
      userStore.requestForFundRegulationsAuthorization(userStore.currentUserInstId).then(() => {
        setAccepted(true);
      });
      setTimeout(() => {
        setSent(true);
      }, 20000);
    }
  };
  const validationSchema = Yup.object().shape({
    token: Yup.string()
      .nullable()
      .matches(RegexHelpers.tokenRegExp, 'Nieprawidłowe hasło')
      .length(6, 'Nieprawidłowa długość hasła')
      .required('Hasło jest wymagane'),
  });
  return (
    <InstitutionAdminDashboard>
      <div className={'tw-min-h-screen tw-bg-gray-100 tw-p-2'}>
        <Formik
          enableReinitialize
          initialValues={{
            token: '',
            userInstitutionId: userStore.currentUserInstId ?? 0,
            error: null,
          }}
          onSubmit={({ token, userInstitutionId }, { setErrors }) =>
            userStore
              .acceptFundRegulations(userInstitutionId, token)
              .then(() => {
                props.onSave?.();
                toast.success('Regulamin został zaakceptowany.');
              })
              .catch(() => setErrors({ error: 'Wystąpił błąd podczas akceptowania regulaminu' }))
          }
          validationSchema={validationSchema}>
          {({ handleSubmit, isSubmitting, handleChange, validateForm }) => (
            <>
              <div className={'tw-mx-auto tw-max-w-4xl '}>
                <div className={'tw-rounded-xl tw-bg-white'}>
                  <SubpageHeader
                    headerClassName={twMerge('tw-text-3xl sm:tw-text-4xl')}
                    iconComponent={<PiggyBankIcon className={'sm:tw-mr-4'} />}
                    title={'Fundusz'}
                  />
                  <div className={'tw-p-4 tw-text-center'}>
                    <div className={'tw-p-2'}>
                      Zapoznaj się z treścią{' '}
                      <span
                        className={'tw-cursor-pointer tw-font-bold tw-text-green-500'}
                        // onClick={() =>
                        //   window.open(`${PathRoute.INSTITUTION_ADMIN_FUND_REGULATIONS_FILE}`, '_system', 'location=yes')
                        // }
                        onClick={() => {
                          modalStore.openModal(
                            <div>
                              <PdfViewer file={PathRoute.INSTITUTION_ADMIN_FUND_REGULATIONS_FILE} />
                            </div>,
                            'lg',
                            'Regulamin funduszu',
                          );
                        }}>
                        regulaminu
                      </span>{' '}
                      i zaakceptuj ją za pomocą hasła z sms.
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}></div>
                    <br />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}>
                      <Button
                        disabled={!sent}
                        onClick={() =>
                          validateForm().then((err) => {
                            handleAccept();
                          })
                        }
                        type='button'
                        variant='primary'>
                        Wygeneruj hasło sms do akceptacji regulaminu
                      </Button>
                      {accepted && (
                        <div className={'tw-pt-6'}>
                          Hasło zostało wysłane na Twój numer telefonu, powinieneś otrzymać je w ciągu kilkunastu
                          sekund.
                        </div>
                      )}
                    </div>
                    {accepted && (
                      <Form autoComplete='off' className='ui form' onSubmit={handleSubmit}>
                        <MyTextMaskedInput
                          mask={MaskHelpers.token}
                          name={`token`}
                          onChange={handleChange}
                          placeholder='Kod z sms'
                          style={{}}
                        />
                        <div
                          style={{
                            marginTop: '15px',
                          }}>
                          <div className={'d-flex flex-column flex-md-row justify-content-md-center gap-2'}>
                            {isSubmitting ? (
                              <Button type='submit' variant='primary'>
                                <span className='m-1'>
                                  <Spinner
                                    animation='grow'
                                    aria-hidden='true'
                                    as='span'
                                    role='status'
                                    size='sm'
                                    variant='light'
                                  />
                                </span>
                                Zapisywanie...
                              </Button>
                            ) : (
                              <Button type='submit' variant='primary'>
                                Oświadczam, że zapoznałem/zapoznałam się z treścią regulaminu
                              </Button>
                            )}
                          </div>
                        </div>
                      </Form>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </InstitutionAdminDashboard>
  );
}

export default InstitutionAdminFundRegulationsForm;
