import React from 'react';
import * as styles from './styles';
import { useSwrAgent } from '../../../api/useSwrAgent';
import SkeletonTextLoader from '../../../components/Loadings/SkeletonTextLoader/SkeletonTextLoader';
import { Image } from 'react-bootstrap';
import { PathRoute } from '../../../constants/pathRoute/Route';
import LinkIcon from '../../../components/Icons/LinkIcon';
import { openLink } from '../../../utils/linkUtils';
import CashbackMoneyTable from '../CashbackInflowsTable/CashbackMoneyTable';
import MyButton from '../../../components/Buttons/MyButton/MyButton';
import CashbackCustomerSearch from '../CashbackCustomerSearch/CashbackCustomerSearch';
import { useStore } from '../../../store/store';
import { history } from '../../../index';

const CashbackMain = () => {
  const { modalStore } = useStore();

  const { data: cashbackBenefitInfo, isLoading } = useSwrAgent().Cashback.GetCashbackBenefitInfo();

  return (
    <div className={'tw-py-4'}>
      {cashbackBenefitInfo?.hasOtherPolicy && (
        <div className={'tw-mb-6 tw-text-sm tw-text-red-600'}>
          Twoje dane zostały przypisane do innego nr polisy. Skontaktuj się z Biurem Obsługi Klienta pod nr tel.{' '}
          <a href='tel:+22226967270'>22 696 72 70</a>
        </div>
      )}

      <p style={styles.headingTextStyle}>Dziękujemy za dołączenie do Programu "Zwrot składki".</p>
      <p className={'tw-mb-8 tw-mt-8'} style={styles.textStyle}>
        <div className={'tw-mb-6 tw-mt-8 tw-flex tw-items-center tw-gap-2'}>
          Twoje aktualne saldo:{' '}
          {isLoading ? (
            <SkeletonTextLoader className={'tw-w-24'} />
          ) : (
            cashbackBenefitInfo?.cashbackBalanceDto?.remainingCashback.toFixed(2) ?? '-'
          )}{' '}
          zł
        </div>
        <div className={'tw-mb-6 tw-flex tw-items-center tw-gap-2'}>
          {isLoading || !cashbackBenefitInfo ? (
            <SkeletonTextLoader className={'tw-w-28'} />
          ) : (
            <CashbackMoneyTable cashbackBenefitInfo={cashbackBenefitInfo} />
          )}
        </div>
        <div className={'tw-mb-2'}>
          Zgromadzone środki możesz wykorzystać na zakup dowolnego ubezpieczenia indywidualnego dostępnego w ofercie NAU, w maksymalnej wysokości do 25% ceny ubezpieczenia.
        </div>
        <div className={'tw-my-6 tw-flex tw-justify-center'}>
          <div className={'tw-grid tw-max-w-[400px] tw-grid-cols-2 tw-gap-4'}>
            <div
              className={'tw-group tw-relative tw-flex tw-cursor-pointer tw-flex-col tw-items-center tw-gap-2'}
              onClick={() => history.push(PathRoute.CAR_INSURANCE)}>
              <Image src={'/assets/cashback/car.svg'} style={{ height: '50px' }} />
              <div className={'tw-text-center tw-transition group-hover:tw-scale-105'} style={styles.textStyle}>
                Ubezpieczenie komunikacyjne
              </div>
              <LinkIcon className={'tw-absolute tw-right-4 tw-h-5 tw-w-5 tw-text-gray-700'} />
            </div>
            <div
              className={'tw-group tw-relative tw-flex tw-cursor-pointer tw-flex-col tw-items-center tw-gap-2'}
              onClick={() => history.push(PathRoute.PROPERTY_INSURANCE)}>
              <Image src={'/assets/cashback/shield.svg'} style={{ height: '50px' }} />
              <div className={'tw-text-center'} style={styles.textStyle}>
                Ubezpieczenie majątkowe
              </div>
            </div>
            <div
              className={'tw-group tw-relative tw-flex tw-cursor-pointer tw-flex-col tw-items-center tw-gap-2'}
              onClick={() => history.push(PathRoute.TRIP_INSURANCE)}>
              <Image src={'/assets/cashback/plane.svg'} style={{ height: '50px' }} />
              <div className={'tw-text-center'} style={styles.textStyle}>
                Ubezpieczenie turystyczne
              </div>
            </div>
          </div>
        </div>
        <div className={'tw-mb-2'}>
          Aby wykorzystać zgromadzone środki na wybrane ubezpieczenie indywidualne, skontaktuj się z naszym Biurem
          Obsługi Klienta:
        </div>
        <div className={'tw-mb-2'}>● zadzwoń pod numer 22 696 72 70</div>
        <div className={'tw-mb-2'}>● napisz maila na adres bok@nau.pl</div>
      </p>
      {cashbackBenefitInfo && !cashbackBenefitInfo?.hasLifeCashbackPolicy && (
        <div className={'tw-mt-4 tw-flex tw-justify-center'}>
          <MyButton
            onClick={() =>
              modalStore.openModal(<CashbackCustomerSearch onSave={() => modalStore.closeModal()} />, 'lg', null)
            }
            variant={'greenDark'}>
            Aktywuj zwrot składki w placówce, <br /> w której posiadasz ubezepieczenie na życie
          </MyButton>
        </div>
      )}
    </div>
  );
};

export default CashbackMain;
