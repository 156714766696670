import * as React from 'react';
import MyButton from '../../components/Buttons/MyButton/MyButton';
import * as Accordion from '@radix-ui/react-accordion';
import { ChevronUp } from 'lucide-react';
import FuelCardIcon from '../../components/Icons/FuelCardIcon'; // Using Lucide icons

interface BenefitItem {
  text: string;
  subtext?: string;
}

interface DiscountItem {
  type: React.ReactNode;
  discounts: React.ReactNode[];
  footnote?: React.ReactNode;
}
interface Props {
  onNext?: () => void;
}

const FuelCardsInitialInfoPage: React.FC<Props> = ({ onNext }) => {
  const benefits = [
    {
      text: <>Rabaty na paliwo i akcesoria</>,
    },
    {
      text: <>Bezgotówkowe tankowanie</>,
    },
    {
      text: <>Minimum formalności</>,
      subtext: <>Umowa na czas nieokreślony</>,
    },
    {
      text: <>Karta z miesięcznym limitem do 900 zł</>,
      subtext: <>Tankujesz kiedy chcesz, tyle ile potrzebujesz</>,
    },
    {
      text: (
        <>
          <span className='tw-font-normal'>Kartę paliwową do </span>
          <span className='tw-text-[#e6224c]'>Circle K</span> i <span className='tw-text-[#0bc268]'>BP</span>{' '}
          otrzymujesz za <span className='tw-font-semibold'>0 zł</span>, do{' '}
          <span className='tw-text-[#ed8d39]'>Shell</span> za jedyne{' '}
          <span className='tw-font-semibold'>1,23 zł/mies.</span>
        </>
      ),
    },
  ];

  const steps = [
    {
      text: (
        <>
          <span className='tw-font-semibold'>Wypełnij wniosek</span> o kartę <span className=''>Circle K</span>,{' '}
          <span className=''>Shell</span> lub <span className=''>BP</span> i wybierz z jakiej karty chcesz korzystać
          (możesz wybrać max. 2 karty na jeden nr rej)
        </>
      ),
    },
    {
      text: (
        <>
          <span className='tw-font-semibold'>Pobierz umowę i podpisz ją online</span> za pomocą SMS
        </>
      ),
    },
    {
      text: (
        <>
          <span className='tw-font-semibold'>Gotowe</span> - ciesz się z atrakcyjnych rabatów na paliwo!
        </>
      ),
    },
    {
      text: (
        <>
          <span className='tw-font-semibold'>Tankuj paliwo</span> na stacjach <span className=''>Circle K</span>,{' '}
          <span className=''>Shell</span> lub <span className=''>BP</span> do wysokości limitu.
        </>
      ),
    },
    {
      text: (
        <>
          <span className='tw-font-semibold'>Płać kartą paliwową</span> odpowiednią dla danego dostawcy paliwa
          (potwierdzasz transakcję kodem PIN).
        </>
      ),
    },
    {
      text: (
        <>
          <span className='tw-font-semibold'>Odbierz dokument "WZ"</span> potwierdzający zakup paliwa.{' '}
          <span className='tw-font-semibold'>Cena na tym etapie nie uwzględnia rabatu od NAU Profit.</span>
        </>
      ),
    },
    {
      text: (
        <>
          Po zakończonym miesiącu kalendarzowym otrzymujesz mailem{' '}
          <span className='tw-font-semibold'>fakturę z uwzględnionym rabatem</span> i końcową kwotą do zapłaty.
        </>
      ),
    },
    {
      text: (
        <>
          <span className='tw-font-semibold'>Opłacasz fakturę</span> w ciągu 5 dni od daty otrzymania.
        </>
      ),
    },
  ];

  <span className='tw-font-semibold'>1,23 zł/mies.</span>;
  const discounts = [
    {
      type: <span className='tw-font-bold tw-text-[#e6224c]'>Circle K</span>,
      discounts: [
        <>
          <span className='tw-font-semibold tw-text-[#e6224c]'>15</span> gr/l* – Paliwo Standard (miles)
        </>,
        <>
          <span className='tw-font-semibold tw-text-[#e6224c]'>20</span> gr/l – Paliwo Premium (milesPLUS)
        </>,
        <>
          <span className='tw-font-semibold tw-text-[#e6224c]'>15</span> gr/l* – Olej napędowy Standard
        </>,
        <>
          <span className='tw-font-semibold tw-text-[#e6224c]'>10</span> gr/l – LPG
        </>,
        <>
          <span className='tw-font-semibold tw-text-[#e6224c]'>20%</span> – Myjnia, olej silnikowy, płyn do spryskiwaczy
        </>,
      ],
      footnote: (
        <span className='tw-text-xs tw-text-gray-500'>
          * 8 gr/l brutto na Circle K Automat i Circle K Express (paliwo standard, olej napędowy standard)
        </span>
      ),
    },
    {
      type: <span className='tw-text-[#ed8d39]'>Shell</span>,
      discounts: [
        <>
          <span className='tw-font-semibold tw-text-[#e6224c]'>12</span> gr/l – Paliwo Standard
          <br />
          (Shell FuelSave 95, Shell FuelSave Diesel)
        </>,
        <>
          <span className='tw-font-semibold tw-text-[#e6224c]'>14</span> gr/l – Paliwo Premium
          <br />
          (V-Power 95, V-Power Diesel)
        </>,
        <>
          <span className='tw-font-semibold tw-text-[#e6224c]'>12</span> gr/l – Olej napędowy
          <br />
          (V-Power ON)
        </>,
        <>
          <span className='tw-font-semibold tw-text-[#e6224c]'>7</span> gr/l – LPG
          <br />
          (AutoGas)
        </>,
        <>
          <span className='tw-font-semibold tw-text-[#e6224c]'>15%</span> – Myjnia, odkurzacz, olej silnikowy, płyn do
          spryskiwaczy
        </>,
      ],
      footnote: (
        <span className='tw-text-sm tw-text-gray-600'>
          Opłata miesięczna za korzystanie karty Shell wynosi 1,23 zł i jest doliczana do faktury. Opłata jest pobierana
          również w przypadku, gdy nie korzystasz z karty.
        </span>
      ),
    },
    {
      type: <span className='tw-text-[#0bc268]'>BP</span>,
      discounts: [
        <>
          <span className='tw-font-semibold tw-text-[#e6224c]'>10</span> gr/l – Paliwo Standard (BP 95)
        </>,
        <>
          <span className='tw-font-semibold tw-text-[#e6224c]'>10</span> gr/l – Paliwo Premium (Ultimate 98)
        </>,
        <>
          <span className='tw-font-semibold tw-text-[#e6224c]'>10</span> gr/l – Olej napędowy (ON, ON Ultimate)
        </>,
        <>
          <span className='tw-font-semibold tw-text-[#e6224c]'>10</span> gr/l – LPG
        </>,
        <>
          <span className='tw-font-semibold tw-text-[#e6224c]'>10%</span> – Myjnia automatyczna i ręczna, płyn do
          spryskiwaczy, płyn do chłodnic
        </>,
      ],
    },
  ];

  return (
    <div className='tw-flex tw-flex-col tw-gap-6 '>
      <div>
        <h2 className='tw-mb-6  tw-text-center tw-text-2xl tw-font-normal tw-text-nau-green-dark'>
          Złóż wniosek o karty paliwowe <span className='tw-font-semibold'>Circle K, Shell, BP i tankuj taniej!</span>
        </h2>

        <h3 className='tw-mb-4 tw-text-2xl tw-font-bold tw-text-nau-green-dark'>Otrzymujesz:</h3>
        <div className='tw-mb-6 tw-flex tw-flex-col tw-gap-3'>
          {benefits.map((benefit, index) => (
            <div className='tw-flex tw-items-start tw-gap-2' key={index}>
              <div className='tw-mt-2 tw-h-1.5 tw-w-1.5 tw-rounded-full tw-bg-green-600' />
              <div className='tw-flex tw-flex-col'>
                <span className='tw-font-semibold tw-text-nau-green-dark'>{benefit.text}</span>
                {benefit.subtext && (
                  <span className='tw-text-sm tw-font-normal tw-text-nau-green-dark'>{benefit.subtext}</span>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <Accordion.Root className='tw-w-full' defaultValue={['steps', 'discounts']} type='multiple'>
        <Accordion.Item className='tw-border-b tw-border-gray-200' value='steps'>
          <Accordion.Trigger className='tw-group tw-flex tw-w-full tw-items-center tw-justify-between tw-py-4'>
            <h3 className='tw-text-xl tw-font-semibold tw-text-nau-green-dark'>Zobacz jakie to proste</h3>
            <ChevronUp className='tw-transform tw-transition-transform group-data-[state=closed]:tw-rotate-180' />
          </Accordion.Trigger>
          <Accordion.Content className='group-data-[state=closed]:tw-animate-accordion-up group-data-[state=open]:tw-animate-accordion-down tw-overflow-hidden'>
            <div className='tw-flex tw-flex-col tw-gap-4 tw-py-4'>
              {steps.map((step, index) => (
                <div className='tw-flex tw-gap-3' key={index}>
                  <div className='tw-mt-2 tw-flex tw-h-1.5 tw-w-1.5 tw-flex-none tw-items-center tw-justify-center tw-rounded-full tw-bg-green-600' />
                  <p className='tw-text-gray-700'>{step.text}</p>
                </div>
              ))}
            </div>
          </Accordion.Content>
        </Accordion.Item>

        <Accordion.Item className='tw-border-b tw-border-gray-200' value='discounts'>
          <Accordion.Trigger className='tw-group tw-flex tw-w-full tw-items-center tw-justify-between tw-py-4'>
            <h3 className='tw-text-xl tw-font-semibold tw-text-nau-green-dark'>Rabaty</h3>
            <ChevronUp className='tw-transform tw-transition-transform group-data-[state=closed]:tw-rotate-180' />
          </Accordion.Trigger>
          <Accordion.Content className='group-data-[state=closed]:tw-animate-accordion-up group-data-[state=open]:tw-animate-accordion-down tw-overflow-hidden'>
            <div className='tw-flex tw-flex-col tw-gap-6 tw-py-4'>
              {discounts.map((card, index) => (
                <div key={index}>
                  <div className='tw-mb-4 tw-flex tw-items-center tw-gap-3'>
                    <div className=''>
                      <FuelCardIcon className='tw-text-green-600' />
                    </div>
                    <h4 className='tw-font-semibold tw-text-nau-green-dark'>
                      Korzystając z karty {card.type} zyskujesz rabat:
                    </h4>
                  </div>
                  <ul className='tw-marker:text-green-600 tw-list-disc tw-space-y-2 tw-pl-6'>
                    {card.discounts.map((discount, idx) => (
                      <li className='marker:tw-text-green-600' key={idx}>
                        {discount}
                      </li>
                    ))}
                  </ul>
                  {card.footnote && <div className='tw-mt-3 tw-pl-6 tw-text-xs tw-text-gray-500'>{card.footnote}</div>}
                </div>
              ))}
            </div>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion.Root>

      <div className='tw-mt-4 tw-flex tw-justify-center'>
        <MyButton className='tw-min-w-[200px]' onClick={onNext} variant='greenDark'>
          Dalej
        </MyButton>
      </div>
    </div>
  );
};

export default FuelCardsInitialInfoPage;
