import { observer } from 'mobx-react-lite';
import React from 'react';
import stylesScss from './styles.module.scss';
import { UserPayment } from '../../../types/payments';
import { Button, Image } from 'react-bootstrap';
import classNames from 'classnames';
import { PaymentTabsE } from '../../../enums/paymentTabs.enums';
import { Benefit } from '../../../enums/benefits.enums';
import { useStore } from '../../../store/store';
import CreateAutopayPaymentForm from '../CreateAutopayPaymentForm/CreateAutopayPaymentForm';

interface IPaymentsListProps {
  paymentsList: UserPayment[];
  paymentTabType: PaymentTabsE;
}

export default observer((props: IPaymentsListProps) => {
  const { paymentsStore, modalStore } = useStore();

  const createAutopayLink = (settlementId?: number, productSettlementId?: number) => {
    if (!settlementId || !productSettlementId) {
      return;
    }
    const settlementIds = [settlementId];
    const productSettlementIds = [productSettlementId];
    paymentsStore.createAutopayLinkInSop(settlementIds, productSettlementIds).then((response) => {
      if (response) {
        window.location.href = response;
      }
    });
  };
  const openModalToCreateAutopayLink = () => {
    const possibleToAutopayPayment = props.paymentsList.filter(
      (x) => x.settlementId && x.productSettlementId && !x.settled,
    );
    modalStore.openModal(<CreateAutopayPaymentForm payments={possibleToAutopayPayment} />, 'lg', 'Wskaż płatności');
  };
  const getIconSrc = (payment: UserPayment) => {
    if (payment.settled) {
      return 'assets/fileCheckedGreen.svg';
    }
    if (payment.late) {
      return 'assets/fileError.svg';
    }
    return 'assets/fileCash.svg';
  };
  return (
    <>
      {/*props.paymentTabType === PaymentTabsE.ToPay && (
        <div>
          <Button variant='primary' onClick={openModalToCreateAutopayLink}>
            Opłać wiele przez Autopay
          </Button>
        </div>
      )*/}
      {props.paymentsList.map((payment) => (
        <div className={stylesScss.paymentItem} key={payment.id}>
          <div className={stylesScss.icon}>
            <Image src={getIconSrc(payment)} style={{ width: '100%' }} />
          </div>
          <div className={stylesScss.dataContainer}>
            <div
              className={classNames(
                stylesScss.data,
                props.paymentTabType === PaymentTabsE.ToPay && payment.late && stylesScss.expired,
              )}>
              <div className={stylesScss.title}>{payment.benefit}</div>
              {/*<div className={stylesScss.description}> {payment.description}</div>*/}
              {payment.paymentDateFormatted && (
                <div className={stylesScss.paymentDate}>Termin zapłaty: {payment.paymentDateFormatted}</div>
              )}
              {payment.bankAccountNumber && (
                <div className={stylesScss.bankAccountNumber}>
                  Nr konta bankowego do spłaty należności: {payment.bankAccountNumber}
                </div>
              )}
            </div>
            {(payment.benefit == 'Karta paliwowa' || payment.productSettlementId) &&
            props.paymentTabType === PaymentTabsE.Paid ? (
              <div className={stylesScss.amount}>{payment.amountFormatted}</div>
            ) : (
              <div className={stylesScss.amount}>{payment.amountToPayFormatted}</div>
            )}
          </div>
          {/*!payment.settled && payment.settlementId && payment.productSettlementId && (
            <div>
              <Button
                variant='primary'
                className='btn-mini tw-ml-4'
                onClick={() => createAutopayLink(payment.settlementId, payment.productSettlementId)}>
                Opłać przez Autopay
              </Button>
            </div>
          )*/}
        </div>
      ))}
    </>
  );
});
