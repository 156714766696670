import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useStore } from '../../store/store';
import MyFooterContact from '../MyFooterContact/MyFooterContact';
import LogoHeader from '../LogoHeader/LogoHeader';
import { PathRoute } from '../../constants/pathRoute/Route';
import PromoCodeBox from '../PromoCode/PromoCodeBox';
import MyButton from '../Buttons/MyButton/MyButton';
import * as Yup from 'yup';
import MyCheckbox from '../FormControls/MyCheckbox/MyCheckbox';
import FileHelper from '../../helpers/FileHelper';
import { CASHBACK_POLICY_DATA, INSTITUTION_TOKEN, PROMO_CODE_KEY } from '../../constants/sessionStorageKeys';
import PinInput from 'react-pin-input';
import MyTextInput from '../FormControls/MyTextInput/MyTextInput';
import { toast } from 'react-toastify';
import { getPathOrRedirect } from '../../utils/linkUtils';

const setNewPasswordValidationSchema = (variant: string) =>
  Yup.object().shape({
    token: Yup.string().required('Pole jest wymagane'),
    password: Yup.string().required('Pole jest wymagane'),
    confirmPassword: Yup.string()
      .required('Pole jest wymagane')
      .oneOf([Yup.ref('password'), null], 'Hasła nie są takie same'),
    ...(variant === 'registration' && {
      regulations: Yup.boolean().nullable().oneOf([true], 'Zgoda jest wymagana').required('Zgoda jest wymagana'),
      privacyPolicy: Yup.boolean().nullable().oneOf([true], 'Zgoda jest wymagana').required('Zgoda jest wymagana'),
    }),
  });

interface ISetNewPasswordProps {
  userId: string;
  variant: 'registration' | 'forgotPassword';
}

const SetNewPassword = observer((props: ISetNewPasswordProps) => {
  const { userStore } = useStore();

  if (userStore.isLoggedIn) {
    const redirectPath = getPathOrRedirect(PathRoute.PUSTY);
    return <Redirect to={redirectPath} />;
  }

  return (
    <Formik
      initialValues={{
        token: '',
        password: '',
        confirmPassword: '',
        regulations: props.variant === 'registration' ? null : undefined,
        privacyPolicy: props.variant === 'registration' ? null : undefined,
        error: undefined,
      }}
      onSubmit={(values, { setErrors, setSubmitting }) => {
        userStore
          .verifyNewPassword({
            ...values,
            userId: props.userId,
            promoCode: undefined,
            cashbackSopPolicyDto: undefined,
          })
          .then(() => {
            sessionStorage.removeItem(CASHBACK_POLICY_DATA);
            sessionStorage.removeItem(PROMO_CODE_KEY);
            sessionStorage.removeItem(INSTITUTION_TOKEN);
          })
          .catch((error) => {
            if (Array.isArray(error)) {
              if (error.length === 1) {
                toast.error(error[0]);
              }
            }
            setSubmitting(false);
          });
      }}
      validationSchema={setNewPasswordValidationSchema(props.variant)}>
      {({ values, handleSubmit, isSubmitting, errors, setFieldValue, validateField }) => (
        <Form
          autoComplete='off'
          className='tw-mx-auto tw-max-w-2xl tw-px-2'
          onSubmit={handleSubmit}
          style={{ paddingBottom: '55px', marginTop: '3vh' } as React.CSSProperties}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '8vh',
            }}>
            <LogoHeader />
          </div>
          <h2
            style={{
              color: 'teal',
              textAlign: 'center',
              marginBottom: '50px',
              marginTop: '4vh',
            }}>
            {props.variant === 'registration' ? 'Zarejestruj się' : 'Zresetuj Hasło'}
          </h2>
          {props.variant === 'registration' && <PromoCodeBox />}
          <div className='tw-mb-4 tw-text-center tw-font-normal tw-text-nau-green-dark sm:tw-text-left'>
            Wpisz token, który otrzymałeś sms-em
          </div>
          <div className={'tw-flex tw-flex-col tw-items-center tw-justify-center tw-font-normal'}>
            <div className={'tw-hidden sm:tw-block'}>
              <PinInput
                initialValue={values.token}
                inputMode='number'
                inputStyle={{
                  borderColor: 'gray',
                  color: 'white',
                  backgroundColor: '#a6a6a6',
                  borderRadius: '19px',
                  width: '64px',
                  height: '94px',
                  textAlign: 'center',
                  fontSize: '30px',
                  fontWeight: 'bold',
                }}
                length={6}
                onChange={(value: string) => {
                  setFieldValue('token', value);
                  validateField('token');
                }}
                onComplete={(value: string) => {
                  setFieldValue('token', value);
                  validateField('token');
                }}
                style={{ padding: '10px' }}
                type='numeric'
              />
            </div>

            <div className={'sm:tw-hidden '}>
              <PinInput
                initialValue={values.token}
                inputMode='number'
                inputStyle={{
                  borderColor: 'gray',
                  color: 'white',
                  backgroundColor: '#a6a6a6',
                  borderRadius: '19px',
                  width: '40px',
                  height: '60px',
                  textAlign: 'center',
                  fontSize: '26px',
                  fontWeight: 'bold',
                }}
                length={6}
                onChange={(value: string) => {
                  setFieldValue('token', value);
                  validateField('token');
                }}
                onComplete={(value: string) => {
                  setFieldValue('token', value);
                  validateField('token');
                }}
                style={{ padding: '10px' }}
                type='numeric'
              />
            </div>

            <div
              className={`tw-transition-grid-rows tw-grid tw-overflow-hidden tw-duration-200  ${
                errors.token ? 'tw-grid-rows-[1fr]' : 'tw-grid-rows-[0fr]'
              }`}>
              <p className='tw-min-h-0 tw-text-left tw-text-sm tw-text-red-600' id='email-error'>
                {errors.token} &nbsp; {/*&nbsp; is necessary for animation */}
              </p>
            </div>
          </div>

          <div className='tw-mb-4 tw-mt-4 tw-font-normal tw-text-nau-green-dark'>Stwórz hasło</div>
          <div className={'tw-flex tw-flex-col tw-gap-4 tw-font-normal'}>
            <MyTextInput name='password' placeholder='Hasło' type={'password'} />
            <MyTextInput name='confirmPassword' placeholder='Powtórz Hasło' type={'password'} />
          </div>

          {props.variant === 'registration' && (
            <div className={'tw-mt-6 tw-flex tw-flex-col tw-gap-4 tw-font-normal'}>
              <MyCheckbox
                className={'tw-text-nau-green-dark'}
                label={
                  <div className='approval-content'>
                    Akceptuję{' '}
                    <span
                      className='tw-transform-gpu tw-cursor-pointer tw-text-nau-green-light tw-underline tw-transition-all tw-duration-200'
                      onClick={() => FileHelper.downloadFile(PathRoute.REGULATIONS, 'Regulamin serwisu.pdf')}>
                      regulamin serwisu
                    </span>
                  </div>
                }
                name={`regulations`}
                variant={'big'}
              />
              <MyCheckbox
                className={'tw-text-nau-green-dark'}
                label={
                  <div className='approval-content'>
                    Akceptuję{' '}
                    <span
                      className='tw-transform-gpu tw-cursor-pointer tw-text-nau-green-light tw-underline tw-transition-all tw-duration-200'
                      onClick={() => FileHelper.downloadFile(PathRoute.PRIVACY_POLICY, 'Polityka prywatności.pdf')}>
                      politykę prywatności
                    </span>
                  </div>
                }
                name={`privacyPolicy`}
                variant={'big'}
              />
            </div>
          )}

          <MyButton className={'tw-mt-8 tw-w-full'} isLoading={isSubmitting} type={'submit'} variant={'primary'}>
            {props.variant === 'registration' ? 'Zarejestruj się' : 'Zresetuj Hasło'}
          </MyButton>

          {props.variant === 'registration' && (
            <div className='tw-flex tw-justify-center tw-pt-12'>
              {!userStore.updateRequired && (
                <Link
                  className={'tw-text-base tw-text-nau-green-dark hover:tw-text-nau-green-dark'}
                  to={PathRoute.LOGIN}>
                  Posiadasz już konto? <span className={'tw-font-semibold'}>Zaloguj się</span>
                </Link>
              )}
            </div>
          )}
          <MyFooterContact />
        </Form>
      )}
    </Formik>
  );
});

export default SetNewPassword;
