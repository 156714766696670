import React, { useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { twMerge } from 'tailwind-merge';

interface IPdfViewerProps {
  file: string;
}

export const PdfViewer = ({ file }: IPdfViewerProps) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState(1.5);
  const [isZoom, setIsZoom] = useState<boolean>(false);
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const toggleZoom = () => {
    setIsZoom((prev) => {
      if (prev) {
        setScale(scale / 1.5);
      } else {
        setScale(scale * 1.5);
      }
      return !prev;
    });
  };

  useEffect(() => {
    const containerWidth = containerRef.current?.offsetWidth;
    // Assuming 800 is the default page width you want to scale from
    const scale = containerWidth ? containerWidth / 640 : 1;
    setScale(scale);
  }, [numPages]); // Rec

  return (
    <div
      className={twMerge('w-full overflow-x-auto', isZoom ? 'cursor-zoom-out' : 'cursor-zoom-in')}
      onClick={() => toggleZoom()}
      ref={containerRef}>
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess} renderMode='canvas'>
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            className={'w-full'}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            scale={scale}
          />
        ))}
      </Document>
    </div>
  );
};
