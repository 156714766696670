import { useField } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import * as styles from '../styles';
import { MyRadioButtonProps } from './types';

export default function MyRadioButton(props: MyRadioButtonProps) {
  const [field, { error, touched }] = useField(props.name);
  const [value, setValue] = useState<any>(props.value);
  const selectHandler = (selectedValue: any): void => {
    setValue(selectedValue);
    props.onChange && props.onChange(selectedValue);
  };
  return (
    <>
      <Form.Group
        style={
          props.column
            ? (styles.groupContainerColumn as React.CSSProperties)
            : (styles.groupContainer as React.CSSProperties)
        }
        className={props.className}>
        {props.options.map((option, index) => (
          <Form.Check
            isInvalid={touched && !!error}
            key={index}
            {...field}
            {...option}
            checked={option.value == (props.value ?? value)}
            id={props.id}
            name={props.name}
            className='my-radio-button'
            onChange={(e) => selectHandler(e.target.value)}
            disabled={props.disabled}
            type='radio'
          />
        ))}
      </Form.Group>
      {touched && !!error && (
        <div
          className={`tw-transition-grid-rows tw-grid tw-overflow-hidden tw-duration-200  ${
            error ? 'tw-grid-rows-[1fr]' : 'tw-grid-rows-[0fr]'
          }`}>
          <p className='tw-min-h-0 tw-text-left tw-text-sm tw-text-red-600' id={`${props.name}-error`}>
            {error} &nbsp; {/*&nbsp; is necessary for animation */}
          </p>
        </div>
      )}
    </>
  );
}
