import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useStore } from '../../store/store';
import CustomerDashboard from '../CustomerDashboard/CustomerDashboard';
import LoadingComponent from '../../components/Loadings/LoadingComponent/LoadingComponent';
import CircleKCard from '../../components/CircleK/CircleKCard/CircleKCard';
import { toast } from 'react-toastify';
import CircleKApplicationForCard from '../../components/CircleK/CircleKApplicationForCard/CircleKApplicationForCard';
import { Benefit } from '../../enums/benefits.enums';
import ApprovalsForm from '../../components/ApprovalsForm/ApprovalsForm';
import MyButton from '../../components/Buttons/MyButton/MyButton';
import { Image } from 'react-bootstrap';
import FuelCardsInitialInfoPage from './FuelCardsInitialInfoPage';
import SubpageHeader from '../../components/SubpageHeader/SubpageHeader';

const CircleKMainPage: React.FC = observer(() => {
  const { circleKStore, userStore, modalStore } = useStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [hasApprovals, setApprovals] = useState<boolean>(true);
  const [showApplication, setShowApplication] = useState<boolean>(true);

  useEffect(() => {
    initializeData();
  }, []);

  const initializeData = async () => {
    try {
      const hasRequiredApprovals = await userStore.hasRequiredApprovalsForBenefit(Benefit.CircleK);
      setApprovals(hasRequiredApprovals);

      if (hasRequiredApprovals) {
        await Promise.all([
          circleKStore.getCircleKCardsByUserId(),
          circleKStore.getCircleKMyExpensesByUserId(),
          circleKStore.getCircleKSettlements(),
        ]);

        userStore.checkEmailIsConfirmed();
        circleKStore.getCircleKBalanceByUserId();
      }
    } catch (error) {
      console.error('Error initializing data:', error);
      toast.error('Wystąpił błąd podczas ładowania danych');
    } finally {
      setLoading(false);
    }
  };

  const renderCards = (isInstitutional: boolean) => {
    const filteredCards = circleKStore.circleKCard.filter((card) => card.isInstitutional === isInstitutional);

    if (filteredCards.length === 0) return null;

    return (
      <div className='tw-space-y-4'>
        <h6 className='tw-text-lg tw-font-semibold tw-text-gray-700'>
          {isInstitutional ? 'Karty firmowe' : 'Karty prywatne'}
        </h6>
        {filteredCards.map((card, index) => (
          <div className='tw-rounded-lg tw-bg-white tw-shadow-sm' key={index}>
            <CircleKCard card={card} />
          </div>
        ))}
      </div>
    );
  };

  if (!userStore.isLoggedIn || !userStore.isActive) return null;

  return (
    <CustomerDashboard>
      <LoadingComponent content='Ładowanie...' visible={loading} />
      <div className='tw-mx-auto tw-max-w-3xl '>
        <SubpageHeader headerClassName={'tw-text-3xl'} title={'Karty paliwowe'} />
        <div className='tw-rounded-b-lg tw-bg-white tw-p-6'>
          {showApplication && !circleKStore.hasAnyNauProfitCard ? (
            <FuelCardsInitialInfoPage onNext={() => setShowApplication(false)} />
          ) : !hasApprovals ? (
            <ApprovalsForm
              benefitId={Benefit.CircleK}
              hideHeader={true}
              onSubmitSuccess={() => {
                initializeData();
              }}
            />
          ) : (
            <div className='tw-space-y-6'>
              {circleKStore.circleKCard.length === 0 ? (
                <div className=''>
                  <CircleKApplicationForCard inMainView={true} />
                </div>
              ) : (
                <>
                  {renderCards(false)}
                  {renderCards(true)}

                  {!circleKStore.hasNewContract && circleKStore.hasAnyNauProfitCard && !circleKStore.cantGetNewCard && (
                    <div className='tw-mt-6 tw-flex tw-justify-center'>
                      <MyButton
                        className='tw-flex tw-items-center tw-gap-2'
                        onClick={() =>
                          modalStore.openModal(<CircleKApplicationForCard />, 'lg', 'Wniosek o nową kartę!')
                        }
                        variant='greenDark'>
                        <Image className='tw-h-6 tw-w-6' src='/assets/card.svg' />
                        Wnioskuj o kolejną kartę
                      </MyButton>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </CustomerDashboard>
  );
});

export default CircleKMainPage;
